import PhysiologyJumpRepository from '@/shared/http/repositories/socialProject/physiology-jump'
import { loading, toast } from '@/shared/utils/UIHelper'
import PhysiologyJumpModel from '@/shared/models/physiologyJump'
import { IsSuccessCode } from '@/shared/utils/API'
import download from 'downloadjs'

export default {
  name: 'PhysiologyJump',

  props: {
    items: {
      type: Object,
      default: {}
    },
    hideExportButton: {
      type: Boolean,
      default: true
    }
  },

  data: () => ({
    physiologyJumpModel: new PhysiologyJumpModel(),
    emptyText: 'Não há nada para exibir',
    seasonName: '',
    showModalDeleteAverage: false,
    showModalInputAverage: false,
    disableExportButton: true,
    literatureName: null,
    className: null,
    literature: null,
    studentsJump: [],
    selectedJump: {},
    totalRegister: 0,
    classAverage: 0,
    jump: 0,
    jump2: 0,
    jump3: 0,
    pagination: {
      count: 1,
      limit: 10,
      index: 1
    },
    fieldsStudentsJump: [
      {
        key: 'studentName',
        label: 'NOME',
        class: 'left-header-border text-center',
        sortable: true
      },
      {
        key: 'jumpAverage',
        label: 'MÉDIA DO SALTO',
        class: 'middle-header text-center',
        sortable: true
      },
      {
        key: 'action',
        label: 'AÇÕES',
        class: 'right-header-border text-center'
      }
    ]
  }),

  computed: {
    calculateAverage() {
      const calc1 = (parseFloat(this.jump) + parseFloat(this.jump2) + parseFloat(this.jump3))
      const calc2 = calc1 / 3

      return this.selectedJump.jumpAverage = calc2.toFixed(1)
    },

    checkColor () {
      const number = this.selectedJump.jumpAverage
      if (number < this.literature){
        return 'lower-average'
      } else if (number == this.literature) {
        return 'in-average'
      }
      return 'top-average'
    }
  },

  watch: {
    items: { // Recebe do PhysiologyList e PhysiologyEdit
      handler: function (value) {
        if (value.id) {
          this.seasonName = value.name
          this.className = value.className
          this.literature = value.jumpLiterature
          this.literatureName = value.jumpLiteratureName
          this.GetByPhysiologyId(value.id)
        }
      },
      immediate: true
    }
  },

  created() {
  },

  methods: {
    GetByPhysiologyId(id) {
      loading.push()
      PhysiologyJumpRepository.GetPhysiologyJumpByPhysiologyId(id, this.pagination)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.studentsJump = res.data.data

          this.pagination = {
            count: res.data.count,
            limit: res.data.pagingLimit,
            index: res.data.pagingIndex
          }

          if (res.data.count > 0) {
            this.totalRegister = res.data.count
            this.disableExportButton = false
          } else {
            this.disableExportButton = true
          }

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar tabela dos saltos', 'ERRO')
        })
    },

    onSave() {
      this.$validator.validateAll().then(isValid => {
        if (!isValid) {
          toast.error('É necessário preencher os campos obrigatórios antes de continuar', 'ERRO')
          return
        }
      })

      loading.push()
      PhysiologyJumpRepository.Update(this.selectedJump)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.GetByPhysiologyId(this.items.id)
          this.showModalInputAverage = false
          this.onClearModalInputAverage()
          this.$emit('reloadGraphicJump')
          toast.success('Salto registrado com sucesso', 'CADASTRO DE SALTO')

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao salvar saltos', 'ERRO')
        })
    },

    onDelete() {
      loading.push()
      PhysiologyJumpRepository.Delete(this.id)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.GetByPhysiologyId(this.items.id)
          this.showModalDeleteAverage = false
          this.$emit('reloadGraphicJump')
          toast.success('Média zerada com sucesso!', 'EXCLUSÃO DE MÉDIA')
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao excluir um salto', 'ERRO')
        })
    },

    downloadExcel() {
      loading.push()
      PhysiologyJumpRepository.ExportExcel(this.items.id, this.classAverage)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          if(res.data.data === null) {
            toast.warning('Esta consulta não tem registros e não irá gerar planilha')
            loading.pop()
            return Promise.resolve()
          }

          const file = res.data.data
          const base64 = `data:${file.contentType};base64,${file.base64}`
          download(base64, file.alias, file.contentType)

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao exportar excel', 'ERRO')
        })
    },

    selectRow(item) {
      this.showModalDeleteAverage = true
      this.id = item.id
    },

    clearDrawer() {
      this.studentsJump = []
      this.totalRegister = 0
      this.className = null
      this.literature = null
      this.physiologyJumpModel = new PhysiologyJumpModel()
      this.selectedJump = {}
      this.$emit('clearItems')
    },

    onShowInputAverage (item) {
      this.selectedJump = new PhysiologyJumpModel(item)
      this.showModalInputAverage = true
    },

    onClearModalInputAverage() {
      this.jump = 0
      this.jump2 = 0
      this.jump3 = 0
      this.selectedJump.jumpAverage = null
      this.selectedJump = {}
      this.showModalInputAverage = false
    },

    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    }
  }
}
