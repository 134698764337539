import PhysiologySprintRepository from '@/shared/http/repositories/socialProject/physiology-sprint'
import PhysiologySprintModel from '@/shared/models/physiologySprint'
import { loading, toast } from '@/shared/utils/UIHelper'
import { IsSuccessCode } from '@/shared/utils/API'
import download from 'downloadjs'


export default {
  name: 'PhysiologySprint',

  props: {
    items: {
      type: Object,
      default: {}
    },
    hideExportButton: {
      type: Boolean,
      default: true
    }
  },
  
  data: () => ({
    physiologySprintModel: new PhysiologySprintModel(),
    emptyText: 'Não há nada para exibir',
    seasonName: '',
    showModalDeleteSprint: false,
    showModalInputSprint: false,
    disableExportButton: true,
    literatureName: null,
    totalRegister: null,
    literature: null,
    className: null,
    studentName: null,
    classAverage: 0,
    studentSprint: [],
    fieldsStudentSprint: [
      {
        key: 'studentName',
        label: 'NOME',
        class: 'left-header-border text-center',
        sortable: true
      },
      {
        key: 'sprintAverage',
        label: 'MÉDIA(s)',
        class: 'middle-header text-center',
        sortable: true
      },
      {
        key: 'bestTime',
        label: 'MELHOR(s)',
        class: 'middle-header text-center',
        sortable: true
      },
      {
        key: 'worstTime',
        label: 'PIOR(s)',
        class: 'middle-header text-center',
        sortable: true
      },
      {
        key: 'ifPercent',
        label: 'IF(%)',
        class: 'middle-header text-center',
        sortable: true
      },
      {
        key: 'ifSeconds',
        label: 'IF(s)',
        class: 'middle-header text-center',
        sortable: true
      },
      {
        key: 'totalTime',
        label: 'TT(s)',
        class: 'middle-header text-center',
        sortable: true
      },
      {
        key: 'action',
        label: 'AÇÕES',
        class: 'right-header-border text-center'
      }
    ],
    pagination: {
      count: 1,
      limit: 10,
      index: 1
    }
  }),

  computed: {
  },

  watch: {
    items: { // Recebe do PhysiologyList e PhysiologyEdit
      handler: function (value) {
        if (value.id) {
          this.seasonName = value.name
          this.className = value.className
          this.literature = value.sprintLiterature
          this.literatureName = value.sprintLiteratureName
          this.GetByPhysiologyId(value.id)
        }
      },
      immediate: true
    }
  },

  created() {
  },

  methods: {
    GetByPhysiologyId(id) {
      loading.push()
      PhysiologySprintRepository.GetPhysiologySprintByPhysiologyId(id, this.pagination)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.studentSprint = res.data.data

          this.pagination = {
            count: res.data.count,
            limit: res.data.pagingLimit,
            index: res.data.pagingIndex
          }

          if (res.data.count > 0) {
            this.totalRegister = res.data.count
            this.disableExportButton = false
          } else {
            this.disableExportButton = true
          }

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar tabela da sprint', 'ERRO')
        })
    },

    onSave() {
      this.$validator.validateAll().then(isValid => {
        if (!isValid) {
          toast.error('Preencha os campos antes de continuar', 'ERRO')
          return
        }
      
      loading.push()
      PhysiologySprintRepository.Update(this.physiologySprintModel)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.GetByPhysiologyId(this.items.id)
          this.showModalInputSprint = false
          this.onClearModalInputSprint()
          this.$emit('reloadGraphicSprint')
          toast.success('Sprint registrado com sucesso', 'CADASTRO DE SPRINT')

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao salvar sprint', 'ERRO')
        })
      })
    },

    onDelete() {
      loading.push()
      PhysiologySprintRepository.Delete(this.id)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.GetByPhysiologyId(this.items.id)
          this.showModalDeleteSprint = false
          this.$emit('reloadGraphicSprint')
          toast.success('Sprint deletada com sucesso!', 'EXCLUSÃO DE SPRINT')
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao excluir uma sprint', 'ERRO')
        })
    },
    
    downloadExcel() {
      loading.push()
      PhysiologySprintRepository.ExportExcel(this.items.id, this.classAverage)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          if(res.data.data === null) {
            toast.warning('Esta consulta não tem registros e não irá gerar planilha')
            loading.pop()
            return Promise.resolve()
          }

          const file = res.data.data
          const base64 = `data:${file.contentType};base64,${file.base64}`
          download(base64, file.alias, file.contentType)
          
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao exportar excel', 'ERRO')
        })
    },

    selectRow(item) {
      this.showModalDeleteSprint = true
      this.id = item.id
    },

    clearDrawer() {
      this.studentSprint = []
      this.totalRegister = 0
      this.classAverage = 0
      this.className = null
      this.literature = null
      this.studentName = null
      this.$emit('clearItems')
    },

    onShowInputSprint (item) {
      this.studentName = item.studentName
      this.physiologySprintModel = new PhysiologySprintModel(item)
      this.showModalInputSprint = true
    },

    onClearModalInputSprint() {
      this.physiologySprintModel = new PhysiologySprintModel()
      this.physiologySprintModel.physiologyId = null
      this.showModalInputSprint = false
    },

    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    }
  }
}