import GuidUtils from '@/shared/utils/guid'

export default class PhysiologyJumpModel {
  constructor({
    id,
    jumpAverage,
    jumpId,
    literature,
    studentId,
    studentName,
    status
  } = {}) {
    this.id = id || GuidUtils.emptyGuid,
    this.jumpAverage = jumpAverage
    this.jumpId = jumpId
    this.literature = literature
    this.studentId = studentId
    this.studentName = studentName
    this.status = status
  }
}