import PhysiologyYoyoRepository from '@/shared/http/repositories/socialProject/physiology-yoyo'
import PhysiologyYoyoModel from '@/shared/models/physiologyYoyo'
import { loading, toast } from '@/shared/utils/UIHelper'
import { IsSuccessCode } from '@/shared/utils/API'
import download from 'downloadjs'

export default {
  name: 'PhysiologyYoyo',

  props: {
    items: {
      type: Object,
      default: {}
    },
    hideExportButton: {
      type: Boolean,
      default: true
    }
  },
  
  data: () => ({
    physiologyYoyoModel: new PhysiologyYoyoModel(),
    emptyText: 'Não há nada para exibir',
    seasonName: '',
    showModalDeleteYoyo: false,
    showModalInputYoyo: false,
    disableExportButton: true,
    literatureName: null,
    totalRegister: null,
    literature: null,
    className: null,
    studentName: null,
    classAverage: 0,
    studentYoyo: [],
    fieldsStudentYoyo: [
      {
        key: 'studentName',
        label: 'NOME',
        class: 'left-header-border text-center',
        sortable: true
      },
      {
        key: 'distance',
        label: 'DISTÂNCIA(m)',
        class: 'middle-header text-center',
        sortable: true
      },
      {
        key: 'action',
        label: 'AÇÕES',
        class: 'right-header-border text-center'
      }
    ],
    pagination: {
      count: 1,
      limit: 10,
      index: 1
    }
  }),

  computed: {
    checkColor () {
      const number = this.physiologyYoyoModel.distance
      if (number < this.literature){
        return 'lower-average'
      } else if (number == this.literature) {
        return 'in-average'
      }
      return 'top-average'
    }
  },

  watch: {
    items: { // Recebe do PhysiologyList e PhysiologyEdit
      handler: function (value) {
        if (value.id) {
          this.seasonName = value.name
          this.className = value.className
          this.literature = value.yoyoLiterature
          this.literatureName = value.yoyoLiteratureName
          this.GetByPhysiologyId(value.id)
        }
      },
      immediate: true
    }
  },

  created() {
  },

  methods: {
    GetByPhysiologyId(id) {
      loading.push()
      PhysiologyYoyoRepository.GetPhysiologyYoyoByPhysiologyId(id, this.pagination)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.studentYoyo = res.data.data

          this.pagination = {
            count: res.data.count,
            limit: res.data.pagingLimit,
            index: res.data.pagingIndex
          }

          if (res.data.count > 0) {
            this.totalRegister = res.data.count
            this.disableExportButton = false
          } else {
            this.disableExportButton = true
          }

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar tabela da yoyo', 'ERRO')
        })
    },

    onSave() {
      this.$validator.validateAll().then(isValid => {
        if (!isValid) {
          toast.error('Preencha os campos antes de continuar', 'ERRO')
          return
        }
      
      loading.push()
      PhysiologyYoyoRepository.Update(this.physiologyYoyoModel)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.GetByPhysiologyId(this.items.id)
          this.showModalInputYoyo = false
          this.onClearModalInputYoyo()
          this.$emit('reloadGraphicYoyo')
          toast.success('Distância registrado com sucesso', 'CADASTRO DE YOYO')

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao salvar YOYO', 'ERRO')
        })
      })
    },

    onDelete() {
      loading.push()
      PhysiologyYoyoRepository.Delete(this.id)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.GetByPhysiologyId(this.items.id)
          this.showModalDeleteYoyo = false
          this.$emit('reloadGraphicYoyo')
          toast.success('Yoyo deletado com sucesso!', 'EXCLUSÃO DE YOYO')
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao excluir um yoyo', 'ERRO')
        })
    },
    
    downloadExcel() {
      loading.push()
      PhysiologyYoyoRepository.ExportExcel(this.items.id, this.classAverage)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          if(res.data.data === null) {
            toast.warning('Esta consulta não tem registros e não irá gerar planilha')
            loading.pop()
            return Promise.resolve()
          }

          const file = res.data.data
          const base64 = `data:${file.contentType};base64,${file.base64}`
          download(base64, file.alias, file.contentType)
          
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao exportar excel', 'ERRO')
        })
    },

    selectRow(item) {
      this.showModalDeleteYoyo = true
      this.id = item.id
    },

    clearDrawer() {
      this.studentYoyo = []
      this.totalRegister = 0
      this.classAverage = 0
      this.className = null
      this.literature = null
      this.studentName = null
      this.$emit('clearItems')
    },

    onShowInputYoyo (item) {
      this.studentName = item.studentName
      this.physiologyYoyoModel = new PhysiologyYoyoModel(item)
      this.showModalInputYoyo = true
    },

    onClearModalInputYoyo() {
      this.physiologyYoyoModel = new PhysiologyYoyoModel()
      this.showModalInputYoyo = false
    },

    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    }
  }
}