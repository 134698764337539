import GuidUtils from '@/shared/utils/guid'

export default class PhysiologySprintModel {
  constructor({
    id,
    physiologyId,
    sprintAverage,
    bestTime,
    sprintLiterature,
    studentId,
    studentName,
    worstTime,
    ifPercent,
    ifSeconds,
    totalTime,
    status
  } = {}) {
    this.id = id || GuidUtils.emptyGuid,
    this.sprintAverage = sprintAverage
    this.physiologyId = physiologyId
    this.bestTime = bestTime
    this.sprintLiterature = sprintLiterature
    this.studentId = studentId
    this.studentName = studentName
    this.worstTime = worstTime
    this.ifPercent = ifPercent
    this.ifSeconds = ifSeconds
    this.totalTime = totalTime
    this.status = status
  }
}