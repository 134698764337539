import GuidUtils from '@/shared/utils/guid'

export default class PhysiologyYoyoModel {
  constructor({
    id,
    physiologyId,
    studentId,
    studentName,
    distance,
    yoyoLiterature,
    status
  } = {}) {
    this.id = id || GuidUtils.emptyGuid,
    this.physiologyId = physiologyId
    this.studentId = studentId
    this.studentName = studentName
    this.distance = distance
    this.yoyoLiterature = yoyoLiterature
    this.status = status
  }
}